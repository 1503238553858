/*Expand the footer width*/
.footer-top.footer.container {
     max-width:96%;
}
#footer {
     background:linear-gradient(269.95deg, #83BEDC -10%, rgba(131, 190, 220, 0) 80%);
}
/*align the caret > with the text*/
#footer ul.bullet li {
     background-position:0;
}
/*Column heading*/
#footer .block-title.heading {
     font-family:'Verdana', sans-serif !important;
     font-size:18px;
     font-weight:700;
     line-height:22px;
     letter-spacing:0em;
     color:#243158;
     text-transform:none;
}
/*Column links*/
#footer ul.bullet li a {
     font-family:'Verdana', sans-serif !important;
     font-size:16px;
     font-weight:400;
     line-height:24px;
     letter-spacing:0em;
     color:#243158;
}

.social-icons i {
     font-size:2em;
     margin-right:10px;
}
/*Copyright text*/
.footer-bottom-container .item.item-left {
     float:none;
     text-align:center;
}
